<template>
  <section>
    <AuditRequirement
      v-for="(rqm, index) of reqmts"
      :key="index"
      :requirement="rqm"
      :number="index"
      :audit="audit"
      :isOpen="isOpen"
      @clicked="setOpen"
    />
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

import AuditRequirement from './AuditRequirement.vue'

export default {
  name: 'AudtTopic',
  props: {
    audit: {
      required: true,
      type: Object
    }
  },
  components: {
    AuditRequirement
  },
  data () {
    return {
      isOpen: 0
    }
  },
  computed: {
    ...mapGetters({
      issueYear: 'manual/issueYear',
      requirements: 'manual/reqmts',
      criteria: 'manual/criteria',
      rqmtById: 'manual/rqmtById',
      criterionById: 'manual/criterionById'
    }),
    reqmts () {
      let req = this.audit.requirements
      if (this.audit.year === this.issueYear) {
        req = this.requirements.filter(e => this.criteria.filter(f => f.ri === e.ri).filter(e => this.audit.criteria.find(f => f.ci === e.ci)).length)
      }
      const tnr = parseInt(this.$route.params.topicId)
      return req ? req.filter(e => e.tnr === tnr).sort((a, b) => a.rnr - b.rnr) : []
    }
  },
  methods: {
    setOpen (number) {
      this.isOpen = number
    }
  }
}
</script>
