<template>
  <div>
    <AuditTopic :audit="audit" />
  </div>
</template>

<script>
import AuditTopic from '@/components/AuditTopic.vue'

export default {
  components: {
    AuditTopic
  },
  props: {
    audit: {
      required: false,
      type: Object,
      default: null
    }
  }
}
</script>
