<template>
  <b-icon :icon="iconSwitch" :class="clsSwitch" />
</template>

<script>
export default {
  name: 'AudtRating',
  props: {
    grade: Number
  },
  computed: {
    clsSwitch () {
      switch (this.grade) {
        case 0:
          return 'has-text-grey-light'
        case 1:
          return 'has-text-danger'
        case 2:
          return 'has-text-warning'
        case 3:
          return 'has-text-success'
        default:
          return 'has-text-danger'
      }
    },
    iconSwitch () {
      switch (this.grade) {
        case 0:
          return 'question'
        case 1:
          return 'thumbs-down'
        case 2:
          return 'thumbs-up'
        case 3:
          return 'thumbs-up'
        default:
          return 'question'
      }
    }
  }
}
</script>
