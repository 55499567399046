<template>
  <div class="content">
    <div class="columns">
      <div class="column">
        <p class="title is-5" :style="{ color: color }">{{criteria.title}}</p>
        <p class="subtitle is-6" style="color:#505050">{{criteria.descr}}</p>
        <p v-if="criteria.pub" class="subtitle is-6 has-text-info"><b-icon icon="comment" /> {{criteria.pub}}</p>
        <p v-if="criteria.int && hasOneOfRoles(['QmsAdmin', 'QmsAuditor'])" class="subtitle is-6" ><b-icon icon="comment" /> {{criteria.int}}</p>
      </div>
      <div class="column is-1">
        <AuditRating :grade="criteria.grade" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AuditRating from './AuditRating.vue'

export default {
  props: {
    criteria: {
      required: true,
      type: Object
    },
    color: {
      required: false,
      default: '',
      type: String
    }
  },
  components: {
    AuditRating
  },
  computed: {
    ...mapGetters({
      hasOneOfRoles: 'auth/hasOneOfRoles'
    })
  }
}
</script>
