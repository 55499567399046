<template>
  <b-collapse
    class="card"
    animation="slide"
    :open="isOpen === number"
    @open="$emit('clicked', number)"
  >
    <div slot="trigger"
      slot-scope="props"
      class="card-header"
      role="button"
    >
      <p class="card-header-title" :style="{ color: requirement.color }">
        {{ requirement.tnr }}.{{ requirement.rnr }} {{ requirement.title }}
      </p>
      <a class="card-header-icon">
        <b-icon :icon="props.open ? 'angle-down' : 'angle-up'"></b-icon>
      </a>
    </div>
    <div class="card-content">
      <AuditCriteria
        v-for="crt in criterias"
        :key="`c_${crt.ci}`"
        :criteria="crt"
        :color="requirement.color"
      />
    </div>
  </b-collapse>
</template>

<script>
import { mapGetters } from 'vuex'

import AuditCriteria from './AuditCriteria.vue'

export default {
  props: {
    requirement: {
      required: true,
      type: Object
    },
    number: {
      required: true,
      type: Number
    },
    audit: {
      required: true,
      type: Object
    },
    isOpen: {
      required: true,
      type: Number
    }
  },
  components: {
    AuditCriteria
  },
  computed: {
    ...mapGetters({
      issueYear: 'manual/issueYear',
      requirements: 'manual/reqmts',
      criteria: 'manual/criteria',
      rqmtById: 'manual/rqmtById',
      criterionById: 'manual/criterionById'
    }),
    criterias () {
      if (this.audit.year === this.issueYear) {
        return this.criteria.filter(e => e.ri === this.requirement.ri).map(this.mergeCriteria).filter(e => this.audit.criteria.find(f => f.ci === e.ci))
      }
      return this.audit.criteria.filter(e => e.ri === this.requirement.ri)
    }
  },
  methods: {
    mergeCriteria (cr) {
      const c = this.audit.criteria.find(f => f.ci === cr.ci)
      return {
        ...cr,
        pub: c ? c.pub : null,
        int: c ? c.int : null,
        grade: c ? c.grade : null
      }
    }
  }
}
</script>
